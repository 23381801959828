// "/users"
// getPath('users');

// "/users/123"
// getPath('users', '123');

// "/users/123/profile"
// getPath('users', '123', 'profile');

// "/users?active=true"
// getPath('users', { active: 'true' });

// "/users/123?role=admin&status=active"
// getPath('users', '123', { role: 'admin', status: 'active' });
export function getPath(
  ...args: Array<
    string | null | undefined | Record<string, string | null | undefined>
  >
) {
  // Check if the last argument is an object (potential query params)
  const lastArg = args[args.length - 1];
  const hasQueryParams = lastArg && typeof lastArg === 'object';

  // Extract path segments and query params
  const pathSegments = hasQueryParams ? args.slice(0, -1) : args;

  const queryParams = hasQueryParams
    ? (lastArg as Record<string, string | null | undefined>)
    : undefined;

  // Join path segments, ensuring they are properly formatted
  const joinedPath = pathSegments
    .filter((segment) => segment !== null && segment !== undefined) // Filter out null/undefined
    .map((segment) => String(segment).replace(/^\/|\/$/g, '')) // Convert to string and remove leading/trailing slashes
    .filter(Boolean) // Remove empty segments
    .join('/');

  const basePath = joinedPath ? `/${joinedPath}` : '/';

  // If no query params, return just the path
  if (!queryParams) {
    return basePath;
  }

  // Create URLSearchParams object
  const searchParams = new URLSearchParams();

  // Add only non-null and non-undefined values
  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== null && value !== undefined) {
      searchParams.append(key, value);
    }
  }

  // Get the query string
  const queryString = searchParams.toString();

  // Return path with query string if it exists
  return queryString ? `${basePath}?${queryString}` : basePath;
}
