import { createContext } from 'react';
import type { OrganizationOutput } from '@topo-io/client';

const OrganizationContext = createContext<{
  organization: Maybe<OrganizationOutput>;
  setOrganization: (organization: Maybe<OrganizationOutput>) => void;
}>({
  organization: undefined,
  setOrganization: (_) => _,
});

export default OrganizationContext;
