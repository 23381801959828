import { useQuery } from '@tanstack/react-query';
import { useTopoClient } from '~/core/hooks/use-topo-client';

export function useUserOrganizationsQuery(authUserId: string) {
  const client = useTopoClient();

  return useQuery({
    queryKey: ['organization', authUserId],
    queryFn: () => client.users.getUserOrganizations(authUserId),
  });
}
