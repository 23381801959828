import { Client } from '@topo-io/client';
import { useMemo } from 'react';
import { useUserSession } from '~/core/hooks/use-user-session';
import { getTraceHeaders } from '~/core/errors/trace-headers';

export const useTopoClient = () => {
  const { userSession } = useUserSession();
  const accessToken = userSession?.auth?.accessToken;
  const backendUrl =
    process.env.NEXT_PUBLIC_BACKEND_URL ?? 'http://localhost:8000';

  const traceHeaders = getTraceHeaders();

  const client = useMemo(
    () =>
      new Client({
        BASE: backendUrl,
        TOKEN: accessToken,
        HEADERS: {
          ...traceHeaders,
        },
      }),
    [accessToken, backendUrl, traceHeaders]
  );
  return client;
};
