'use client';

import { useSignOut } from '@topo-io/supabase';
import Link from 'next/link';
import OrganizationsSelector from '~/app/(workspace)/components/organizations/OrganizationsSelector';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/core/ui/Dropdown';
import Heading from '~/core/ui/Heading';
import Modal from '~/core/ui/Modal';
import Trans from '~/core/ui/Trans';
import { useCurrentOrganization } from '~/lib/organizations/hooks/use-current-organization';
import NAVIGATION_CONFIG from '../navigation.config';
import { Building08, LogOut03, Menu01 } from '@untitled-ui/icons-react';

const MobileAppNavigation = () => {
  const currentOrganization = useCurrentOrganization();

  if (!currentOrganization?.id) {
    return null;
  }

  const Links = NAVIGATION_CONFIG(currentOrganization.id).items.map(
    (item, index) => {
      if ('children' in item) {
        return item.children.map((child) => {
          return (
            <DropdownLink
              key={child.path}
              Icon={child.Icon}
              path={child.path}
              label={child.label}
            />
          );
        });
      }

      if ('divider' in item) {
        return <DropdownMenuSeparator key={index} />;
      }

      return (
        <DropdownLink
          key={item.path}
          Icon={item.Icon}
          path={item.path}
          label={item.label}
        />
      );
    }
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Menu01 className={'h-9'} />
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={10} className={'rounded-none w-screen'}>
        <OrganizationsModal />

        {Links}

        <DropdownMenuSeparator />
        <SignOutDropdownItem />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default MobileAppNavigation;

function DropdownLink(
  props: React.PropsWithChildren<{
    path: string;
    label: string;
    Icon: React.ElementType;
  }>
) {
  return (
    <DropdownMenuItem asChild key={props.path}>
      <Link
        href={props.path}
        className={'flex w-full items-center space-x-4 h-12'}
      >
        <props.Icon className={'h-6'} />

        <span>
          <Trans i18nKey={props.label} defaults={props.label} />
        </span>
      </Link>
    </DropdownMenuItem>
  );
}

function SignOutDropdownItem() {
  const signOut = useSignOut();

  return (
    <DropdownMenuItem
      className={'flex w-full items-center space-x-4 h-12'}
      onClick={signOut}
    >
      <LogOut03 className={'h-6'} />

      <span>
        <Trans i18nKey={'common:signOut'} defaults={'Sign out'} />
      </span>
    </DropdownMenuItem>
  );
}

function OrganizationsModal() {
  return (
    <Modal
      Trigger={
        <DropdownMenuItem
          className={'h-12'}
          onSelect={(e) => e.preventDefault()}
        >
          <button className={'flex items-center space-x-4'}>
            <Building08 className={'h-6'} />

            <span>
              <Trans i18nKey={'common:yourOrganizations'} />
            </span>
          </button>
        </DropdownMenuItem>
      }
      heading={<Trans i18nKey={'common:yourOrganizations'} />}
    >
      <div className={'flex flex-col space-y-6 py-4'}>
        <Heading type={6}>Select an organization below to switch to it</Heading>

        <OrganizationsSelector displayName />
      </div>
    </Modal>
  );
}
