'use client';

import { Close as DialogPrimitiveClose } from '@radix-ui/react-dialog';

import Button from '~/core/ui/Button';
import IconButton from '~/core/ui/IconButton';
import If from '~/core/ui/If';
import Trans from '~/core/ui/Trans';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '~/core/ui/Dialog';
import { X } from '@untitled-ui/icons-react';

type ControlledOpenProps = {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => unknown;
};

type TriggerProps = {
  Trigger?: React.ReactNode;
};

type Props = React.PropsWithChildren<
  {
    heading: string | React.ReactNode;
    description?: string | React.ReactNode;
    closeButton?: boolean;
    autoFocus?: boolean;
    className?: string;
  } & (ControlledOpenProps & TriggerProps)
>;

const Modal: React.FC<Props> & {
  CancelButton: typeof CancelButton;
} = ({
  closeButton,
  heading,
  description,
  children,
  autoFocus = true,
  className,
  ...props
}) => {
  const isControlled = 'isOpen' in props;
  const useCloseButton = closeButton ?? true;

  return (
    <Dialog open={props.isOpen} onOpenChange={props.setIsOpen}>
      <If condition={props.Trigger}>
        <DialogTrigger asChild>{props.Trigger}</DialogTrigger>
      </If>

      <DialogContent
        onOpenAutoFocus={(e) => {
          if (!autoFocus) {
            e.preventDefault();
          }
        }}
        className={className}
      >
        <div className={'flex flex-col space-y-4'}>
          <div className="flex flex-col gap-2">
            <DialogTitle className="flex w-full !text-base font-semibold text-foreground">
              <span className={'max-w-[95%] truncate'}>{heading}</span>
            </DialogTitle>
            {description && (
              <DialogDescription className="text-sm text-sidebar-muted !ml-0 !p-0">
                {description}
              </DialogDescription>
            )}
          </div>

          <div className="relative">{children}</div>

          <If condition={useCloseButton}>
            <DialogPrimitiveClose asChild>
              <IconButton
                className={'absolute top-0 right-4 flex items-center'}
                label={'Close Modal'}
                onClick={() => {
                  if (isControlled && props.setIsOpen) {
                    props.setIsOpen(false);
                  }
                }}
              >
                <X className={'h-6'} />
                <span className="sr-only">Close</span>
              </IconButton>
            </DialogPrimitiveClose>
          </If>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;

function CancelButton<Props extends React.ButtonHTMLAttributes<unknown>>(
  props: Props
) {
  return (
    <Button
      type={'button'}
      data-cy={'close-modal-button'}
      variant={'ghost'}
      {...props}
    >
      <Trans i18nKey={'common:cancel'} />
    </Button>
  );
}

Modal.CancelButton = CancelButton;

export { CancelButton };
