import classNames from 'clsx';
import { useCallback, useContext, useState } from 'react';
import { useAuthUser } from '@topo-io/supabase';
import Link from 'next/link';
import { useMemo } from 'react';
import { getPath } from '~/core/routes/get-path';
import UserSessionContext from '~/core/session/contexts/user-session';
import GlobalRole from '~/core/session/types/global-role';
import If from '~/core/ui/If';
import {
  Select,
  SelectAction,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '~/core/ui/Select';
import Trans from '~/core/ui/Trans';
import { useCurrentOrganization } from '~/lib/organizations/hooks/use-current-organization';
import { useUserOrganizationsQuery } from '~/lib/organizations/hooks/use-user-organizations-query';
import CreateOrganizationModal from './CreateOrganizationModal';
import type {
  UserOrganizationOutput,
  OrganizationDataOutput,
} from '@topo-io/client';
import { Building08, DotsVertical, PlusCircle } from '@untitled-ui/icons-react';
import { setCookie } from '~/core/utils/cookies';
import configuration from '~/configuration';

const OrganizationsSelector = ({ displayName = true }) => {
  const changeOrganization = useChangeOrganization();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const organization = useCurrentOrganization();
  const organizationData: OrganizationDataOutput | null = organization
    ? {
        ...organization,
        customerId: organization.customerId || null,
        domain: organization.domain || null,
        companyName: organization.companyName || null,
        subscription: organization.subscription
          ? organization.subscription.data
          : null,
      }
    : null;
  const { userSession } = useContext(UserSessionContext);

  const authUserId = userSession?.auth?.user?.authUserId ?? '';
  const selectedOrganizationId = organization?.id;

  const { data, isLoading } = useUserOrganizationsQuery(authUserId);
  const { data: authUser } = useAuthUser();

  const isSuperAdmin = useMemo(() => {
    return authUser?.app_metadata.role === GlobalRole.SuperAdmin;
  }, [authUser]);

  return (
    <>
      <Select
        value={selectedOrganizationId}
        onValueChange={(uuid) => changeOrganization(uuid)}
      >
        <SelectTrigger asChild>
          <div
            role={'button'}
            className={classNames(
              'text-sm lg:text-base w-full group hover:bg-gray-50 cursor-pointer border-transparent dark:hover:bg-dark-900/50 dark:hover:text-white',
              {
                'justify-between max-h-12': displayName,
                'rounded-full border-none !p-0.5 mx-auto': !displayName,
              }
            )}
            data-cy={'organization-selector'}
          >
            <OrganizationItem
              organization={organizationData}
              displayName={displayName}
            />

            <If condition={displayName}>
              <DotsVertical
                className={'h-5 hidden group-hover:block text-gray-500'}
              />
            </If>

            <span hidden>
              <SelectValue />
            </span>
          </div>
        </SelectTrigger>

        <SelectContent>
          <SelectGroup>
            <SelectLabel>
              <Trans i18nKey={'common:yourOrganizations'} />
            </SelectLabel>

            <SelectSeparator />

            <OrganizationsOptions organizations={data ?? []} />

            <If condition={isLoading}>
              <SelectItem value={selectedOrganizationId ?? ''}>
                <OrganizationItem organization={organizationData} />
              </SelectItem>
            </If>

            <If condition={isSuperAdmin}>
              <SelectItem value={'admin'}>
                <Link
                  className={'flex h-full w-full items-center space-x-2'}
                  href={'/admin'}
                >
                  <div className="w-6 h-6 border rounded-full text-center items-center flex flex-col p-1">
                    <Building08 className={'h-4'} />
                  </div>
                  <span>Admin</span>
                </Link>
              </SelectItem>
            </If>
          </SelectGroup>

          <SelectSeparator />

          <SelectGroup>
            <SelectAction
              data-cy={'create-organization-button'}
              className={'flex flex-row items-center space-x-2 truncate'}
              onClick={() => setIsModalOpen(true)}
            >
              <PlusCircle className={'h-5'} />

              <span>
                <Trans
                  i18nKey={'organization:createOrganizationDropdownLabel'}
                />
              </span>
            </SelectAction>
          </SelectGroup>
        </SelectContent>
      </Select>

      <CreateOrganizationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        authUserId={authUserId}
      />
    </>
  );
};

function OrganizationsOptions(
  props: React.PropsWithChildren<{
    organizations: UserOrganizationOutput[];
  }>
) {
  return (
    <>
      {props.organizations.map(({ organization }) => {
        return (
          <SelectItem
            data-cy={`organization-selector-${organization.name}`}
            value={organization.id}
            key={organization.id}
          >
            <OrganizationItem displayName organization={organization} />
          </SelectItem>
        );
      })}
    </>
  );
}

function OrganizationItem({
  organization,
  displayName = true,
}: {
  organization: Maybe<OrganizationDataOutput> | null;
  displayName?: boolean;
}) {
  if (!organization) {
    return null;
  }

  return (
    <div
      data-cy={'organization-selector-item'}
      className={classNames('flex max-w-[12rem] items-center space-x-2.5', {
        'w-full': !displayName,
      })}
    >
      <If condition={displayName}>
        <span className={'w-auto truncate text-sm'}>{organization?.name}</span>
      </If>
    </div>
  );
}

export default OrganizationsSelector;

function useChangeOrganization() {
  return useCallback((uuid: string) => {
    setCookie('organizationId', uuid);
    window.location.href = getPath(configuration.paths.appHome);
  }, []);
}
