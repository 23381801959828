import * as Sentry from '@sentry/nextjs';

const getTraceSpan = () => {
  const activeSpan = Sentry.getActiveSpan();
  const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;
  return {
    activeSpan,
    rootSpan,
  };
};

export const getTraceHeaders = () => {
  const { rootSpan } = getTraceSpan();
  // Create `sentry-trace` header
  const sentryTraceHeader = rootSpan
    ? Sentry.spanToTraceHeader(rootSpan)
    : undefined;

  // Create `baggage` header
  const sentryBaggageHeader = rootSpan
    ? Sentry.spanToBaggageHeader(rootSpan)
    : undefined;

  return {
    ...(sentryTraceHeader && { sentryTraceHeader }),
    ...(sentryBaggageHeader && { sentryBaggageHeader }),
  };
};
