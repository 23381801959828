import {
  Activity,
  BarChartSquare01,
  Headphones01,
  Mail02,
  MessageChatCircle,
  Send01,
  Toggle03Right,
  Users03,
} from '@untitled-ui/icons-react';
import configuration from '~/configuration';
import { getPath } from '~/core/routes/get-path';

type Divider = {
  divider: true;
};

type NavigationItemLink = {
  label: string;
  path: string;
  Icon: (props: { className: string }) => JSX.Element;
  end?: boolean;
};

type NavigationGroup = {
  label: string;
  collapsible?: boolean;
  collapsed?: boolean;
  children: NavigationItemLink[];
};

export type NavigationItem = NavigationItemLink | NavigationGroup | Divider;

type NavigationConfig = {
  items: NavigationItem[];
  extra?: NavigationItem[];
};

const paths = configuration.paths;

const NAVIGATION_CONFIG = (): NavigationConfig => ({
  items: [
    {
      label: '',
      collapsible: false,
      children: [
        {
          label: 'common:dashboardTabLabel',
          path: getPath(configuration.paths.appHome),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <BarChartSquare01 className={className} />;
          },
        },
        {
          label: 'common:agentsTabLabel',
          path: getPath(paths.agents),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Headphones01 className={className} />;
          },
        },
        {
          label: 'common:campaignsTabLabel',
          path: getPath(paths.campaigns),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Send01 className={className} />;
          },
        },
        {
          label: 'common:leadsTabLabel',
          path: getPath(paths.leads.contacted),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Users03 className={className} />;
          },
        },
        {
          label: 'common:activityFeedTabLabel',
          path: getPath(paths.activityFeed),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Activity className={className} />;
          },
        },
        {
          label: 'common:masterInboxTabLabel',
          path: getPath(paths.inbox),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Mail02 className={className} />;
          },
        },
      ],
    },
  ],
  extra: [
    {
      label: '',
      collapsible: false,
      children: [
        {
          label: 'common:integrationsTabLabel',
          path: getPath(paths.settings.crm),
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <Toggle03Right className={className} />;
          },
        },
        {
          label: 'common:featureRequestTabLabel',
          path: paths.settings.featureRequest ?? '',
          end: true,
          Icon: ({ className }: { className: string }) => {
            return <MessageChatCircle className={className} />;
          },
        },
      ],
    },
  ],
});

export default NAVIGATION_CONFIG;
